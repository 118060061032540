import React from 'react'

const Contact = () => {
  return (
    <div>
       <iframe src="https://www.shipdartexpress.com/contact" title="Contact Us" style={{width: "100%",
            height: "100vh",
            border: "none"}}></iframe> 
    </div>
  )
}

export default Contact
