import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate,useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';

const Channel_integrate = () => {
    let navigate = useNavigate();
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
 const {channel_id } = useParams();
 const[state,setState] = useState({name:"" , url:"" , key:"" , password:"" , store_id:"" , webhook_url:""})
    const[tempstate,settempState] = useState({name:"" , url:"" , key:"" , password:""})

    // console.log("state === " , state)
    const[isLoading , setisLoading] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const handleChange = (e)=>{
        // let names = e.target.name
        let values = e.target.value
         setState({...state , [e.target.name]:values})
    }
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })

      const addStore = ()=>{
        setisLoading(true)
        if(state.name!="" && state.url!="" && state.key !=""){
           let url = config.apiUrl + '/shipment/store_add_test_api';
           let sendData = {channel_id:channel_id , store_name:state.name , store_url:state.url , store_api_key : state.key ,store_id:state.store_id , webhook_url:state.webhook_url };
           // console.log("bb", sendData);
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
               if(res.data.status==true){    
                   
                   Toast.fire({
                       background:"#001b72",
                       type: 'unsuccess',
                       title: "Store Added Successfully",
                       color:"white"
                     });
                     setisLoading(false)
       navigate('/customer/channel_list')
                 
               }
               else{
                   Toast.fire({
                       background:"#c2200a",
                       type: 'unsuccess',
                       title: res.data.message,
                       color:"white"
                     });
                     setisLoading(false)
               }
       
           }).catch((e) => {
               Toast.fire({
                   background:"#c2200a",
                   type: 'unsuccess',
                   title: "Something Went Wrong",
                   color:"white"
                 });
       
           });
       }
       else{
           Toast.fire({
               background:"#c2200a",
               type: 'unsuccess',
               title: "Fill All the fields",
               color:"white"
             });
       }
       }
    const testWoocommerce = ()=>{
        setisLoading(true)
        const randomUserId = Math.floor(10000 + Math.random() * 90000);

        // Append user_id to the current browser URL
        const newUrl = `${window.location.href}?user_id=Shipdartexpress_${randomUserId}`;
        
        // Replace the current browser URL with the new one
        window.history.replaceState({}, document.title, newUrl);
           let url = config.apiUrl + '/user/generate_woocommerce_keys';
           let sendData = {channel_id:channel_id , store_url:state.url ,  redirectUrl: window.location.origin + `/customer/channel_integrate/${channel_id}`, user_id:`Shipdartexpress_${randomUserId}` };
           // console.log("bb", sendData);
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
               if(res.data.status==true){    
                   setisLoading(false)
                   const authUrl = res.data.url;
                    window.location.href = authUrl;
                  }
               else{
                   Toast.fire({
                       background:"rgb(25, 135, 84)",
                       type: 'unsuccess',
                       title: res.data.message,
                       color:"white"
                     });
                     setisLoading(false)
               }
       
           }).catch((e) => {
               Toast.fire({
                   background:"rgb(25, 135, 84)",
                   type: 'unsuccess',
                   title: "Something Went Wrong",
                   color:"white"
                 });
       
           });
       }

       const getwoocommercegeneratedkeys = ()=>{
        let uid =""
        const currentUrl = window.location.href;
        console.log("currentUrl == " , currentUrl)
        const urluser = new URL(currentUrl);
        console.log("urluser == " , urluser)

        // Get the value of the 'user_id' parameter
        const userId = urluser.searchParams.get('user_id');
        console.log("userId == " , userId)
        if(userId!=null && userId!=undefined){
            uid = userId
        }
           let url = config.apiUrl + '/user/get_woocommerce_keys';
           let sendData = {channel_id:channel_id , user_id:uid};
           // console.log("bb", sendData);
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
               if(res.data.status==true){    
                 
                setState({...state , url:res.data.output.store_url , key:res.data.output.store_api_key , password:res.data.output.store_password})
                settempState({...tempstate ,url:res.data.output.store_url , key:res.data.output.store_api_key , password:res.data.output.store_password})
                  }
               else{
                 
               }
       
           }).catch((e) => {
              
       
           });
       }

       React.useEffect(()=>{
        getwoocommercegeneratedkeys()
       },[])

       const saveWoocommerce = ()=>{
        setisLoading(true)
        let uid =""
        const currentUrl = window.location.href;
        console.log("currentUrl == " , currentUrl)
        const urluser = new URL(currentUrl);
        console.log("urluser == " , urluser)

        // Get the value of the 'user_id' parameter
        // const userId = urluser.searchParams.get('user_id');
        // console.log("userId 00== " , userId)
        // if(userId!=null && userId!=undefined){
        //     uid = userId
        // }
           let url = config.apiUrl + '/shipment/save_wooCoommerce_orders';
           let sendData = {channel_id:channel_id , 
            // store_name:state.name , 
            store_url:state.url , store_api_key : state.key , store_password:  state.password  
            // , unique_user:uid
        };
           // console.log("bb", sendData);
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
               if(res.data.status==true){    
                  
                   Toast.fire({
                       background:"rgb(25, 135, 84)",
                       type: 'unsuccess',
                       title: "Store Added Successfully",
                       color:"white"
                     });
                     setisLoading(false)
       navigate('/customer/channel_list')
                   
               }
               else{
                   Toast.fire({
                       background:"rgb(25, 135, 84)",
                       type: 'unsuccess',
                       title: res.data.message,
                       color:"white"
                     });
                     setisLoading(false)
               }
       
           }).catch((e) => {
               Toast.fire({
                   background:"rgb(25, 135, 84)",
                   type: 'unsuccess',
                   title: "Something Went Wrong",
                   color:"white"
                 });
       
           });
       }

       React.useEffect(() => {
        const randomStoreId = Math.floor(1000000000 + Math.random() * 9000000000); // Generate a 10-digit random number
        const generatedWebhookUrl = `https://backend.shipdartexpress.com/webhook/shopify/${randomStoreId}`;
        // setWebhookUrl(generatedWebhookUrl);
        setState({store_id:randomStoreId , webhook_url:generatedWebhookUrl})
      }, []);
  return (
    <div >
       
       <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section" >
         
          <Header/>
        
         {channel_id=="1567898605"?
        <section class="mb-3  shopify">
          <div class="">
           <h2 >Integrate Shopify</h2> 
            
                

                <div class="row  mb-3">
                    <div class="col-12 col-md-5">
                        <figure>
                            <img src="../../../img/shopify.png" class="img-fluid" style={{height:"100px"}} />
                        </figure>

                        <h5>Shopify</h5>
                    <p>Use the following steps to Integrate Shopify </p>
                    <ol>
    <li>Login into Shopify Admin panel</li>
    <li>Go to Settings</li>
    <li>Scroll down the left menu to find Apps and Sales Channel.</li>
    <li>Click on Develop Apps and then click on Create an app</li>
    <li>Give your app an appropriate name, e.g., <strong>Shipdartexpress</strong></li>
    <li>
        After creating the app, you will see four tabs. Click on <strong>Configuration</strong> and then, under 
        <strong>Admin API integration</strong>, click on <strong>Configure</strong>
    </li>
    <li>
        Provide the required admin access for specific APIs:
        <ul>
            <li><strong>Read & Write Access:</strong> 
            read_apps, write_assigned_fulfillment_orders, read_assigned_fulfillment_orders, write_draft_orders, read_draft_orders, write_fulfillments, read_fulfillments, write_merchant_managed_fulfillment_orders, read_merchant_managed_fulfillment_orders, write_inventory, read_inventory, write_orders, read_orders, write_products, read_products, write_product_listings, read_product_listings, write_product_feeds, read_product_feeds
            </li>
        </ul>
    </li>
    <li>
        Save your changes, go to <strong>Overview</strong>, and install your app. After installation, 
        you will receive the <strong>Admin API access token</strong>.
        <ul>
            <li><strong>Admin API access token:</strong></li>
            <li>
                <strong>Note:</strong> For the store URL, use: 
                <br/>yourstorename.myshopify.com
            </li>
        </ul>
    </li>
    <li>Paste the same credentials here to integrate the Shopify channel</li>
    <li>
        <strong>Webhook Creation Steps:</strong>
        <ul>
            <li>In the Shopify Admin panel, go to <strong>Settings</strong>.</li>
            <li>Click on <strong>Notifications</strong> from the left-hand menu.</li>
            <li>Scroll down to the <strong>Webhooks</strong> section and click on <strong>Create Webhook</strong>.</li>
            <li>
                In the popup:
                <ul>
                    <li>Select the <strong>Event</strong> for which you want the webhook (e.g., Order Creation).</li>
                    <li>Choose the <strong>Format</strong> (JSON is recommended).</li>
                    <li>
                        In the <strong>URL</strong> field, paste the Webhook URL provided:
                        <br/> 
                        <code>https://backend.shipdartexpress.com/webhook/shopify/&lt;store_id&gt;</code>
                    </li>
                </ul>
            </li>
            <li>Click <strong>Save</strong>.</li>
            <li>Your webhook is now active.</li>
        </ul>
    </li>
</ol>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Setting</h6>
                            <div class="row">
                           
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="email" class="form-label">Store Name:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Name" name="name" onChange={(e)=>handleChange(e)} />
                                </div>
                               
                                 
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="email" class="form-label">Store URL:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter URL Link" name="url" onChange={(e)=>handleChange(e)}  />
                                </div>
                                
                                <div class="col-12 col-md-12 mb-3">
                             <label for="email" class="form-label">ACCESS TOKEN KEY:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter ACCESS TOKEN KEY" name="key" onChange={(e)=>handleChange(e)} />
                                </div>
                                <div class="col-12 col-md-12 mb-3">
                             <label for="email" class="form-label">Webhook Url:</label>
                                    <input type="text" class="form-control bg-light" id="" placeholder="Enter ACCESS TOKEN KEY" name="webhook"  value={state.webhook_url} readOnly />
                                </div>
                                
                                {/* <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Shared Secret:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Shared Secret" name="email" />
                                </div> */}
                            </div>

                            <div class="py-4">
                            {isLoading? 
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} /> :
                            <a onClick={()=>addStore()} class="btn btn-primary">Save & Test Integration</a>
                            }
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
          </div>
        </section>
:
        <section class="mb-3 mt-3 shopify">
          <div class="">
           <h3 class="py-3">Integrate WooCommerce</h3> 
                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-5">
                        <figure>
                            <img src="https://cdn.shipdartexpress.com/channel_images/wooCommerce.png" class="img-fluid" style={{height:"100px"}} />
                        </figure>

                        <h5>WooCommerce</h5>
                         <p>Use the following steps to integrate WooCommerce:</p> <ol> <li>Login to your WordPress Admin panel.</li> <li>Navigate to <strong>Plugins</strong> and click on <strong>Add New</strong>.</li> <li>Search for <strong>WooCommerce</strong> and install the plugin if it's not already installed.</li> <li>Once WooCommerce is installed, click on <strong>Activate</strong>.</li> <li>Now, go to the <strong>WooCommerce</strong> tab in the sidebar and click on <strong>Settings</strong>.</li> <li>In the settings panel, navigate to the <strong>Advanced</strong> tab and click on <strong>REST API</strong>.</li> <li>Click on <strong>Add Key</strong> to generate a new API key.</li> <li>Give your API key an appropriate name (for example, "Shipdartexpress"), and choose the <strong>Read/Write</strong> permission for full API access.</li> <li>Click on <strong>Generate API Key</strong>. You will now get your <strong>Consumer Key</strong> and <strong>Consumer Secret</strong>.</li> <li>Use these credentials to integrate your WooCommerce store here.</li> <li><strong>Note:</strong> The store URL should be in this format: <br/> <strong>https://yourstorename.com</strong></li> </ol>
                  
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Setting</h6>
                            <div class="row">

                                 
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="email" class="form-label">Store URL:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter URL Link" name="url" onChange={(e)=>handleChange(e)} value={state.url} />
                                </div>
                                {/* {tempstate.url!=""? */}
                                <div class="col-12 col-md-6 mb-3">
                            <label for="email" class="form-label">Consumer Key:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter API KEY" name="key" onChange={(e)=>handleChange(e)}  value={state.key}/>
                                </div>
                                {/* :""} */}
                               

                               {/* {tempstate.url!=""? */}
                                <div class="col-12 col-md-6 mb-3">
                           <label for="email" class="form-label">Consumer Secret:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Password" name="password" onChange={(e)=>handleChange(e)}  value={state.password}/>
                                </div>
                                {/* :""} */}
                                
                             
                            </div>

                            <div class="py-4">
                                {/* {tempstate.url==""? */}
                            {isLoading? 
    //   <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} /> :
    //                         <a onClick={()=>testWoocommerce()} class="btn btn-primary">Connect To WooCommerce</a>
                            
    //                     :   isLoading? 
                            <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} /> :
                                                  <a onClick={()=>saveWoocommerce()} class="btn btn-primary">Test WooCommerce</a>
                                                  }
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
          </div>
        </section>
}
   

    </div>
    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Channel_integrate
