import React from 'react'

const Terms = () => {
  return (
    <div>
       <iframe src="https://www.shipdartexpress.com/terms" title="Terms & Conditions" style={{width: "100%",
            height: "100vh",
            border: "none"}}></iframe>
    </div>
  )
}

export default Terms
