import React, {  useRef } from 'react'
import Header from './Header'
import Left_menu from './Left_menu'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Barcode from 'react-barcode';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import html2canvas from 'html2canvas';
import BarcodeComponent from './BacodeComponent'
import { renderToString } from 'react-dom/server';
import createRoot from 'react-dom';
import { useNetworkState } from 'react-use';
import { BarcodeIcon, CornerUpLeft, DollarSign, FileDown, Package, PackageCheck, PackageIcon, PackageSearch, ShoppingBag, ShoppingBasket, Truck, TruckIcon } from 'lucide-react';

const Customer_shipments = () => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    const location = useLocation();
    console.log("isONline  === " ,isOnline )
    // const barcodeRef = useRef(null);
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const[ndrmodal , setndrmodal] = React.useState(false)
    const[loaderpickup , setloaderpickup] = React.useState(false)
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const[state,setState]= React.useState({shipment_list:[],isLoading:true})
    const[labelstate,setlabel]= React.useState({shipment_list:[],isLoading:true})
    const[searchstate,setsearchstate]= React.useState({search_shipment:""})

    const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
    const [shipSelect, setShipSelect] = React.useState({ shipment_id: "",cancel_reason:"" , total_amount:0 });
    const [modalState, setModalState] = React.useState({ show: false });
    const [modalpickupState, setModalpickupState] = React.useState({ show: false });
    const[isLoading , setisLoading] =React.useState(true)
    const[pickupstate , setpickupstate] = React.useState({  warehouse_name:""});
    // console.log("pickupstate ==== " , pickupstate)
    const [selectedShipments, setSelectedShipments] = React.useState([]);
    // console.log("selectedShipments === " ,selectedShipments)
    const [selectCount ,setSelectCount] = React.useState({select_count:0 })
    const[addressState, setaddressState] = React.useState([]);
    
    const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:500,page:0,total_count:0,onload:true});
    // console.log("otherState ==== " , otherStates)
    const[isloading , setisloading] = React.useState(true)

    const [selectedDateTime, setSelectedDateTime] = React.useState(null);
    const [selectedTime, setSelectedTime] = React.useState(null);
    const[cancelshipment , setcancelshipment] = React.useState(false)
    const [openOffdetailcanvas, setOpenOffdetailcanvas] = React.useState(false);
    const[bookorder , setbookorder] = React.useState([])
    console.log("bookorder == " , bookorder)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[date, setdate] = React.useState({date:""})
    const[paymentmode, setpaymentmode] = React.useState({cod:false,prepaid:false})
    const[warehouse , setwarehouse] = React.useState([])
    const [selectedWarehouses, setSelectedWarehouses] = React.useState([]);
    const [searchfilters, setsearchFilters] = React.useState({ searchtitle: "",filter:[{name:"booked",value:1,status:true},{name:"assigned",value:2,status:true},{name:"intransit",value:3,status:true},{name:"delivered",value:4,status:true}],filterStatus:[]})
    const[failedstate , setfailed] = React.useState({failed:""})
    const [trackingstate,setTrackingState] = React.useState({track:{},isLoading:true})
    const [stateship, setStateship] = React.useState({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
    const [showComponent, setShowComponent] = React.useState(true);
    React.useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const statusParam = urlParams.get('status');
      if (statusParam && parseInt(statusParam) === 8) {
          // If status is 8, don't show the component
          setShowComponent(false);
      } else {
          setShowComponent(true);
      }
  }, []);
    React.useEffect(() => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      console.log("urlSearchParams == " , urlSearchParams)
      // Get and set the customer_id from the URL
   
      const urlStatus = urlSearchParams.get('status');
      console.log("urlStatus == " , urlStatus)
      const decodedStatus = decodeURIComponent(urlStatus);
      const statusArray = decodedStatus.split(',');
      const statusNumbers = statusArray.map(Number);
      console.log("statusNumbers == " , statusNumbers)
      const order_number = urlSearchParams.get('number');

      if(order_number && !urlStatus){
        setsearchstate({search_shipment:order_number})
        let onLoad = true
        let index = 0 
        if(isOnline.online==true){
          let dataToSend = {customer_id : userData.customer_id ,find:order_number,  limit:otherStates.rowsPerPage,indexValue:index ,   
      };
      if(date.date!=""){
        dataToSend.date = date.date
      }
     if(selectedWarehouses.length>0){
      dataToSend.warehouse = selectedWarehouses
     }
      if (paymentmode.cod && !paymentmode.prepaid) {
        dataToSend.payment_mode = 'cod';
    } else if (!paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'prepaid';
    } else if (paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'both';
    }
  
  
    
  
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/shipment/customer_shipments';
       
       // console.log("headers =========> ", config.headers);
       
        
        axios.post(url, dataToSend, { headers: config.headers })
        // // console.log("headers ====== " , config.headers)
            .then((res) => {
              // console.log("responseJson => ", res);
              if( onLoad) {
                setOtherState({...otherStates,total_count:res.data.count , page:index})         
  
    }
              setState({shipment_list:res.data.output,isLoading:false})
              setisLoading(false)
           
            })
            .catch((error) => {
                //Hide Loader
              //   setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
            });    
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: "Network Connection Error",
              
            })
          }
      }

      else if(!order_number&&urlStatus && statusNumbers.length>0){
        let onLoad = true
        let index = 0 
        if(isOnline.online==true){
          let dataToSend = {customer_id : userData.customer_id ,find:searchstate.search_shipment,  limit:otherStates.rowsPerPage,indexValue:index ,   status:statusNumbers
      };
      if(date.date!=""){
        dataToSend.date = date.date
      }
     if(selectedWarehouses.length>0){
      dataToSend.warehouse = selectedWarehouses
     }
      if (paymentmode.cod && !paymentmode.prepaid) {
        dataToSend.payment_mode = 'cod';
    } else if (!paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'prepaid';
    } else if (paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'both';
    }
  
  
    
  
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/shipment/customer_shipments';
       
       // console.log("headers =========> ", config.headers);
       
        
        axios.post(url, dataToSend, { headers: config.headers })
        // // console.log("headers ====== " , config.headers)
            .then((res) => {
              // console.log("responseJson => ", res);
              if( onLoad) {
                setOtherState({...otherStates,total_count:res.data.count , page:index})         
  
    }
              setState({shipment_list:res.data.output,isLoading:false})
              setisLoading(false)
           
            })
            .catch((error) => {
                //Hide Loader
              //   setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
            });    
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: "Network Connection Error",
              
            })
          }
      }
      else if(order_number&&urlStatus && statusNumbers.length>0){
        let onLoad = true
        let index = 0 
        if(isOnline.online==true){
          let dataToSend = {customer_id : userData.customer_id ,find:order_number,  limit:otherStates.rowsPerPage,indexValue:index ,   status:statusNumbers
      };
      if(date.date!=""){
        dataToSend.date = date.date
      }
     if(selectedWarehouses.length>0){
      dataToSend.warehouse = selectedWarehouses
     }
      if (paymentmode.cod && !paymentmode.prepaid) {
        dataToSend.payment_mode = 'cod';
    } else if (!paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'prepaid';
    } else if (paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'both';
    }
  
  
    
  
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/shipment/customer_shipments';
       
       // console.log("headers =========> ", config.headers);
       
        
        axios.post(url, dataToSend, { headers: config.headers })
        // // console.log("headers ====== " , config.headers)
            .then((res) => {
              // console.log("responseJson => ", res);
              if( onLoad) {
                setOtherState({...otherStates,total_count:res.data.count , page:index})         
  
    }
              setState({shipment_list:res.data.output,isLoading:false})
              setisLoading(false)
           
            })
            .catch((error) => {
                //Hide Loader
              //   setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
            });    
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: "Network Connection Error",
              
            })
          } 
      }
      else{
        shipment_list(null,0,true)
      }
    }, [date.date , paymentmode , selectedWarehouses , searchfilters.filterStatus,otherStates.rowsPerPage]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };

    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 5000
  })

    // console.log("shipmentState",shipmentstate)
    React.useEffect(() => {
  
        // shipment_list(null,0,true)
        dimension_detail()
    address_book_detail()
    // get_warehouse()
    
      }, [])

      // React.useEffect(()=>{
      //   shipment_list(null,0,true)
      // },[date.date , paymentmode , selectedWarehouses , searchfilters.filterStatus,location.search])
    

    const shipment_list=(status,index=0,onLoad)=>{
      if(isOnline.online==true){
        let dataToSend = {customer_id : userData.customer_id ,find:searchstate.search_shipment,  status:status , limit:otherStates.rowsPerPage,indexValue:index
    };
    if(date.date!=""){
      dataToSend.date = date.date
    }
   if(selectedWarehouses.length>0){
    dataToSend.warehouse = selectedWarehouses
   }
    if (paymentmode.cod && !paymentmode.prepaid) {
      dataToSend.payment_mode = 'cod';
  } else if (!paymentmode.cod && paymentmode.prepaid) {
      dataToSend.payment_mode = 'prepaid';
  } else if (paymentmode.cod && paymentmode.prepaid) {
      dataToSend.payment_mode = 'both';
  }

  if(searchfilters.filterStatus.length > 0) {
    dataToSend.status= searchfilters.filterStatus
}
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/customer_shipments';
     
     // console.log("headers =========> ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
      // // console.log("headers ====== " , config.headers)
          .then((res) => {
            // console.log("responseJson => ", res);
            if( onLoad) {
              setOtherState({...otherStates,total_count:res.data.count , page:index})         

  }
            setState({shipment_list:res.data.output,isLoading:false})
            setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Network Connection Error",
            
          })
        }

    }

    const label_shipments=()=>{
      if(isOnline.online==true){
      let dataToSend = {customer_id : userData.customer_id,shipment_id:selectedShipments
  };
    let url = config.apiUrl + '/shipment/download_label_shipments';
     axios.post(url, dataToSend, { headers: config.headers })
        .then((res) => {
          console.log("responseJson => ", res);
          setlabel({shipment_list:res.data.output,total : res.data.total , company_info:res.data.company_info})
          setisloading(false)
         
          
        })
        .catch((error) => {
        
        });    
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Network Connection Error",
          
        })
      }

  }
  
  React.useEffect(() => {
    // This effect will run every time labelstate is updated
    if (labelstate.shipment_list.length > 0) {
      onDownloadPDF();
    }
  }, [labelstate]); 
 
  const onDownloadPDF = async() => {
    const content = [];
    for (const sub of labelstate.shipment_list) {
        try {
          const pdfContent = htmlToPdfmake(`
          <table width="100%" border="0" style="border-collapse: collapse; border: 2px solid #000; font-size: 11px; line-height:1;">
          <tr class="" style="margin:0; padding:0px">
            <td colSpan='2' style='margin:0; padding:0px; font-weight:bold;'>
              Ship To:
            </td>
            <td align="right" style='margin:0; padding:0px;'>
            ${moment(new Date(sub.created * 1000)).format("MMM DD YYYY")}
            </td>
          </tr>
          <tr class="">
            <td colspan="3">
              <strong style="font-size:16px;">${sub.receiver[0].name}</strong></br>
               ${(sub.receiver[0].address)}</br>
                ${sub.receiver[0].city},${sub.receiver[0].state} ${sub.receiver[0].pincode} &nbsp;
                Mob: ${sub.receiver[0].mobile}
              
            </td>
          </tr>
          <tr class="">
            <td class="" style="width: 60%;">
             <strong style='font-size:10px;'>Shipment Mode</strong>:</br>
              ${sub.product_type_name?sub.product_type_name:"------"}</br>
              <strong style='font-size:10px;'>Payment Type</strong>:</br>
              ${sub.payment_mode}
            </td>
            <td colspan="2" style="width: 40%;">
            <strong style='font-size:16px;'>${sub.payment_mode=="cod"?`COD Amount: INR ${sub.cod_amount}`:"DO NOT COLLECT CASH"}</strong>
            </td>
          </tr>
          <tr style="border-bottom:0;">
            <td colspan="2" style="border-bottom:0; text-align:right; border-right:0;">
            <strong style='font-size:14px;'>${sub.service_provider?sub.service_provider:sub.carrier_name}</strong> 
            </td>
            <td colspan="0" style="border-bottom:0;  border-left:0;">
            <strong style='font-size:10px;'>ROUTE ----</strong> 
            </td> 
           </tr>
           <tr style="border-top:0;">
           <td colspan="3" style="margin: 0; padding: 0; line-height: 0; font-size: 0; border-top:0;">
           <img src="${sub.barcode_url_carrier}" style="height: 55px; width: 250px; margin-left:8px; display: block; padding: 0;">
           </img>
           </td>
          </tr>
          <tr class="border-bottom">
          <td>
          Product
      </td>
      <td>
          Qty
      </td>
      <td>
          Price
      </td>
  </tr> 
  ${sub.items.map((item)=>(`
    <tr class="border-bottom">
    <td>
    ${item.item_name}
    </td>
    <td colspan="">
    ${item.item_quantity}
    </td>
    <td colspan="">
        ${item.item_value}
    </td>
</tr>`
  ))}
  <tr class="border-bottom">
    <td>
      Total
    </td>
    <td colspan="">
      
    </td>
    <td colspan="">
    ${sub.totalValue}
    </td>
  </tr>
  <tr>
  <td colspan="3" class="" style="margin: 0; padding: 0; line-height: 0; font-size: 0;">
  <img src="${sub.barcode_url}" style="height: 55px; width: 250px; margin-left:8px; display: block; padding: 0;">
</td>
  </tr>
  <tr style="margin: 0px;">
    <td colspan="3" class="" style="margin: 0px;">
    <strong style='font-weight:bold;'> Return Address</strong>: ${(sub.return[0].address)}, ${sub.return[0].city}-${sub.return[0].pincode}, ${sub.return[0].state}, ${sub.return[0].country}
    </td>
  </tr>
</table>

           `);
  
  
          content.push(pdfContent);
          content.push({ text: '', pageBreak: 'after' });
          setlabel({shipment_list:[]})
        } catch (error) {
          console.error(`Error converting HTML to pdfmake format for shipment :`, error);
        }
    };
    content.pop();
   const pdfDoc = {
      content,
      pageSize: { width: 4 * 72, height: 6 * 72 },
      pageOrientation: 'portrait',
    };
    pdfMake.createPdf(pdfDoc).download('labels.pdf');
  };
    

    const dimension_detail = () =>{
      let full_api = config.apiUrl + `/user/get_company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }
    const handlePageChange = (event,newPage) =>{
      setOtherState({...otherStates,page:newPage})
      // console.log("newPage ", newPage);
      // getCountryList(newPage);
      shipment_list(shipmentstate.shipment_status,newPage,true)
  }
  const handleChangeRowsPerPage = (event , newPage) => {
      // console.log("event ", event.target.value);
      setOtherState({ ...otherStates, rowsPerPage: event.target.value })
       
  }
   

    const cancelModal = (ship) =>{
      // console.log("----ship:   ", ship.shipment_id);
      setShipSelect({cancel_reason:"",shipment_id:ship.shipment_id , total_amount:ship.total_amount});
      setModalState({ show: true })
     
    }

    const cancelShipment = () =>{
      setcancelshipment(true)
      if(isOnline.online==true){
      if(shipSelect.cancel_reason != ""){
      let full_api = config.apiUrl + `/shipment/shipment_cancel`;
      let sendData = {customer_id:userData.customer_id,shipment_id:shipSelect.shipment_id,cancel_reason:shipSelect.cancel_reason , total_amount:shipSelect.total_amount};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        Toast.fire({
          background: "#10b93b",
          type: 'success',
          title: res.data.message,
          color: "white"
      });
        if(res.data.status == "success"){
          setcancelshipment(false)
                setShipSelect({cancel_reason:"",shipment_id:""});
                setModalState({ show: false })
                shipment_list()
        }
        else{
          setcancelshipment(false)
        }
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        setcancelshipment(false)
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }else {
      Toast.fire({
        background: "#10b93b",
        type: 'success',
        title: "Enter Reason to Cancel.",
        color: "white"
    });
    }
  }else{
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: "Network Connection Error",
      
    })
  }
    }

    const inputHandleChange = (e) => {
      const value = e.target.value;
      const text = e.target.name;
      setShipSelect({ ...shipSelect, [text]: value })
    }
    
   const shipping_label =(sub)=>{
    // console.log("sub ==== " , sub)
    if(sub.carrier_id=="1656377059"){
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://dtdcapi.shipsy.io/api/customer/integration/consignment/shippinglabel/stream?reference_number='+sub.carrier_tracking_number,
      responseType: 'blob',
      headers: { 
        'Content-Type': 'application/pdf', 
        'api-key': 'e96fb203eef06314303c1aa7ef8c0c'
      }
    };
    
    axios.request(config)
    .then((response) => {
      // console.log((response.data));
      download(response.data, 'dtdc.pdf')
      
    })
    .catch((error) => {
      // console.log(error);
    });
  
  }
  else{
    let data = new FormData();
data.append('username', 'SHIPORTTECHNOLOGIES-EXSPLUS915066');
data.append('password', '6e3P9Xwy19');
data.append('awbs', sub.carrier_tracking_number);

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://shipment.ecomexpress.in/services/expp/shipping_label',
  
  data : data
};

axios.request(config)
.then((response) => {
  // console.log("ecom ===== ",JSON.stringify(response.data));
  // download(response.data, 'ecom.pdf')
})
.catch((error) => {
  // console.log(error);
});

  }
   }

   const openPickupModal=(sub)=>{
    // console.log("sub===== " , sub)
    if(isOnline.online==true){
    setModalpickupState({...modalpickupState,show:true})
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Network Connection Error",
        
      })
    }
    // setpickupstate({...pickupstate , awb_number:sub.carrier_tracking_number , service_name:sub.carrier_name , carrier_id:sub.carrier_id , address:sub.sender[0].address , state:sub.sender[0].state , city:sub.sender[0].city , postal_code:sub.sender[0].pincode , country:sub.sender[0].country })
   }
  //  const getNext5Dates = () => {
  //   const today = new Date();
  //   const nextDates = [];

  //   for (let i = 0; i < 5; i++) {
  //     const nextDate = new Date(today);
  //     nextDate.setDate(today.getDate() + i);
  //     nextDate.setHours(today.getHours());
  //     nextDate.setMinutes(today.getMinutes());
  //     nextDates.push(formatDate(nextDate));
  //   }

  //   return nextDates;
  // };
  const getNext5Dates = () => {
    const today = new Date();
    const now = new Date(); // Get the current time
  
    const nextDates = [];
  
    // Check if the current time is before 1:00 PM
    if (now.getHours() < 14) {
      // Show today's date
      nextDates.push(formatDate(today));
    }
  
    // Calculate and show the dates for the next 4 days
    for (let i = 1; i < 5; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      nextDate.setHours(today.getHours());
      nextDate.setMinutes(today.getMinutes());
      nextDates.push(formatDate(nextDate));
    }
  
    return nextDates;
  };
  const formatDate = (date) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric'  };
    return date.toLocaleDateString(undefined, options);
  };
  const nextDates = getNext5Dates();
  const handleDateSelect = (date) => {
    setSelectedDateTime(date);
    // You can also close the modal or perform other actions here
  };

  // const get_warehouse = () =>{
  //   let full_api = config.apiUrl + `/shipment/getAllWarehouse`;
  //   let sendData = {};
  
  //   axios.post(full_api, sendData, { headers: config.headers }).then(res => {
  //     if(res.data.status==true){
  //       setwarehouse(res.data.output)
  //     }
  //  console.log("rnmmmmm", res);
  //   }).catch((e) => {
  //     // toast.configure()
  //     //  toast.error("Some thing went wrong")
  //     console.log("----error:   ", e);
  //   })
  // }

   
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedShipments(state.shipment_list.map((shipment) => shipment.shipment_id));
      setSelectCount({select_count : state.shipment_list.length})
    } else {
      setSelectedShipments([]);
      setSelectCount({select_count : 0})

    }
  };
  const handleSelectIndividual = (event, shipmentId) => {
    if (event.target.checked) {
      setSelectedShipments([...selectedShipments, shipmentId]);
      setSelectCount({...selectCount , select_count :selectCount.select_count +1})
    } else {
      setSelectedShipments(selectedShipments.filter((id) => id !== shipmentId));
      setSelectCount({...selectCount , select_count :selectCount.select_count -1})

    }
   
  };

  const address_book_detail = ()=>{
    let url = config.apiUrl + '/country/address_book';
    let sendData = {  customer_id: userData.customer_id };
    // console.log("bb", sendData);
    axios.post(url,sendData ,{headers:config.headers}).then((res)=>{
        if(res.data.status==true){
            // console.log("sasdfdsa")
            setaddressState(res.data.output)
            // setState([])
            // setCityList([])
            // setPincodeList([])
            // setaddState({...addstate, full_name:"" ,  state_name:"" , city_name:"" , pincode:"" , address:"" , email:"" , mobile:"" , warehouse_name:"", err:{} })
            // setOptions([])

             
        }

    }).catch(()=>{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'unsuccess',
            title: "Something Went Wrong",
            color:"white"
          });
   
       
    })
   }

   const inputHandleChangemodal=(e)=>{
    setpickupstate({...pickupstate , warehouse_name:e.target.value})
   }
   const pickup_request = ()=>{
   if(isOnline.online==true){
    let url = config.apiUrl + '/shipment/create_pickup_request_api';
    if(pickupstate.warehouse_name!=""){
      if( selectedDateTime!=null ){
      if(selectedTime!=null){
      setloaderpickup(true)
    let sendData = {  customer_id: userData.customer_id , warehouse_name : pickupstate.warehouse_name , date:selectedDateTime , time:selectedTime};
    // console.log("pickup_request === ", sendData);
    axios.post(url,sendData ,{headers:config.headers}).then((res)=>{
        if(res.data.status==true){
          setModalpickupState({ show: false })
          setloaderpickup(false)
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#206bc4",
            type: 'unsuccess',
            title: res.data.message,
            color:"white"
          });
          shipment_list()
            // // console.log("sasdfdsa")
          
        }
        else{
          setModalpickupState({ show: false })
          setloaderpickup(false)
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#206bc4",
            type: 'unsuccess',
            title: res.data.message,
            color:"white"
          });
        }

    }).catch(()=>{
      setModalpickupState({ show: false })
      setloaderpickup(false)
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#206bc4",
            type: 'unsuccess',
            title: "Something Went Wrong",
            color:"white"
          });
   
       
    })
      }else{
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        
        Toast.fire({
          background:"#206bc4",
          type: 'unsuccess',
          title: "Please Select Time",
          color:"white"
        });
      }
    }else{

    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    })
    
    Toast.fire({
      background:"#206bc4",
      type: 'unsuccess',
      title: "Please Select Date",
      color:"white"
    });
    }
  }else{
  
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    })
    
    Toast.fire({
      background:"#206bc4",
      type: 'unsuccess',
      title: "Please Select All",
      color:"white"
    });
  }
}else{
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: "Network Connection Error",
    
  })
}
   }
   const selectTime=(e)=>{
    setSelectedTime(e.target.value)
   }

   const handlesearchShipment=(e)=>{
    setsearchstate({search_shipment:e.target.value})
   }
   const openOffdetailcanvasHandler = (sub) => {
    setOpenOffdetailcanvas(true);
setbookorder(sub)
shipment_tracking(sub.shipment_id)
shipment_failed(sub.shipment_id)
   
  };
   
  const closeOffdetailcanvasHandler = () => {
    setOpenOffdetailcanvas(false);
    setbookorder([])
    setTrackingState({track:{},isLoading:true})
    setStateship({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
    setfailed({failed:""})
    // Call your get_list function here if needed
    // get_list(sub);
  };  
  const dateselect =(e)=>{
    setdate({date:e.target.value})
 }

 const modeChange =(e , value)=>{
 if(e.target.checked){
setpaymentmode({...paymentmode , [e.target.name]:true})
 }
 else{
   setpaymentmode({...paymentmode , [e.target.name]:false})
 }

 }

 const handleWarehouseChange = (e, warehouseId) => {
   const isChecked = e.target.checked;
   if (isChecked) {
       setSelectedWarehouses([...selectedWarehouses, warehouseId]);
   } else {
       setSelectedWarehouses(selectedWarehouses.filter(id => id !== warehouseId));
   }
};

const filter=(e,values)=>{
  const name = e.target.name;
  const value = values;
  const isChecked = e.target.checked;

  // Create a new array by spreading the existing filterStatus array
  const updatedFilterStatus = [...searchfilters.filterStatus];

  if (isChecked) {
    updatedFilterStatus.push(value);
  } else {
    // Remove the value from the array if unchecked
    const index = updatedFilterStatus.indexOf(value);
    if (index !== -1) {
      updatedFilterStatus.splice(index, 1);
    }
  }

  // Update the searchfilters state with the updated filterStatus array
  setsearchFilters((prevFilters) => ({
    ...prevFilters,
    filterStatus: updatedFilterStatus,
  }));
  
  const selectedStatus = updatedFilterStatus;
  const statusQueryString = `status=${selectedStatus.join(',')}`;
  
  // Get the existing query parameters
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  
  // Set the new status parameter
  searchParams.set('status', selectedStatus.join(','));
  
  // Update the URL with the modified query parameters
  url.search = searchParams.toString();
  window.history.pushState({}, '', url.toString());
}

const shipment_tracking = (shipment_id) =>{
      
  let full_api = config.apiUrl + `/shipment/shipment_tracking`;
 let sendData = {customer_id:userData.customer_id , trackingNumber:shipment_id};
 // console.log("sendData",sendData)
  
 axios.post(full_api, sendData, { headers: config.headers }).then(res => {
  if(res.data.status==200){
     setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
     // console.log("res",trackingstate.track)
     setStateship({ ...stateship, shipmentDetail: res.data.ship_detail, isLoading:false})
   
  }
  else{
   setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
   // console.log("res",trackingstate.track)
   setStateship({ ...stateship, shipmentDetail: res.data.ship_detail, isLoading:false})
  }
 
  
 }).catch((e) => {
   // toast.configure()
    //toast.error("Some thing went wrong")
   // console.log("----error:   ", e);
 })
}


const shipment_failed = (shipment_id) =>{
      
  let full_api = config.apiUrl + `/shipment/check_failed_shipments`;
 let sendData = {shipment_id:shipment_id};
 // console.log("sendData",sendData)
  
 axios.post(full_api, sendData, { headers: config.headers }).then(res => {
  setfailed({...failedstate , failed:res.data.output})
 
  
 }).catch((e) => {
   // toast.configure()
    //toast.error("Some thing went wrong")
   // console.log("----error:   ", e);
 })
}
const openModal=(e)=>{
  setndrmodal(true)
}
  return (
//     <div>
       
//     <Left_menu value={3}/>

//     <section class="home ">
        
// <Header/>
// <Modal show={modalState.show} onHide={() => setModalState({ show: false })}>
// <Modal.Header closeButton>
//                     <Modal.Title>Do you really want to Cancel ?</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body >
//                 <div className="row">
//                       <div className="col-2 mb-3 mt-5" style={{marginLeft:"25px"}}>
//                       <i class="fa fa-envelope " style={{fontSize:"20px",marginTop:"10px"}} aria-hidden="true"></i>
//                       </div>
//                         <div className="col-md-10 mb-3 mt-5 " style={{marginLeft:"-50px"}}>
//                               <input className="form-control" id="first_name" type="text" placeholder="Enter Reason" name="cancel_reason" onChange={(e) => inputHandleChange(e)} required   style={{ background: "transparent", border: "none", borderBottom: "1px solid #000000",  outline:"none", boxShadow:"none"}}/>
//                          </div>
                         
                        
//                        <div>
                       
//                        {cancelshipment==false?
//                     <button className="btn mt-3" style={{color: "#fff", backgroundColor: "#D65142", display:"inline-block",textAlign:"center",verticalAlign:"middle",position:"relative",boxSizing:"border-box",marginLeft:"190px",padding: "0.84rem 2.14rem",
//     fontSize: "18px",fontWeight:"bold"}} onClick={() =>
//       cancelShipment()
//                         }>Yes</button>
//                         :""}
//                     </div>
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
               
//                   <Button variant="secondary" onClick={() => setModalState({ show: false })}>
//                         Close
//                     </Button>
                              
//                 </Modal.Footer>
//             </Modal>


//             <Modal show={modalpickupState.show} onHide={() => setModalpickupState({ show: false })} size='lg'>
// <Modal.Header closeButton>
//                     <Modal.Title> <h4 class="modal-title">Schedule Your Pickup</h4></Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body >
              
//       <div class="modal-body">
//         <div class="row booking_info_bx">
          
//             <div class="col-12 col-md-12 mb-3">
//               <div class="card bg-light border-0 shadow-sm">
//                 <div class="card-body">
//                   <div class="d-flex">
//                       <div class="p-2 text-primary">
//                         <i class="material-icons-outlined">local_shipping</i>
//                       </div>
//                       <div class="ps-4 ">
//                         <h6>Pickup Address</h6>
//                         <select class="form-select" name="warehouse_name" onChange={(e)=>inputHandleChangemodal(e)}>
//                           <option>Select</option>
//                           {addressState.map((sub)=>{
//                             return(
//                               <option value={sub.address_id}>{sub.warehouse_id}</option>
//                             )
                        
//                             })}
                        
//                         </select>
//                       </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="col-12 col-md-12 mb-3">
//               <div class="card bg-light border-0 shadow-sm">
//                 <div class="card-body">
//                   <div class="d-flex">
//                       <div class="p-2 text-primary">
//                         <i class="material-icons-outlined">today</i>
//                       </div>
//                       <div class="ps-4 shcelu_pil">
//                         <h6>Schedule Your Pickup</h6>
//                         <small>Please select a suitable date and time for the pickup.</small>
//                         <div class="mt-3">
//                         {nextDates.map((date, index) => (
//                           <>
//                            <a   class="me-2 btn-time mb-2 text-decoration-none" key={index} onClick={() => handleDateSelect(date)}>{(date)}
//                            <select name="time" onChange={(e)=>selectTime(e)}>
//                             <option value="9:00">9:00</option>
//                             <option value="10:00">10:00</option>
//                             <option value="11:00">11:00</option>
//                             <option value="12:00">12:00</option>
//                             <option value="13:00">13:00</option>
//                             <option value="14:00">14:00</option>
//                             <option value="15:00">15:00</option>
//                             <option value="16:00">16:00</option>
//                             <option value="17:00">17:00</option>
//                             <option value="18:00">18:00</option>
//                           </select>
//                           </a>
                         
                         
//             </>
//           ))}
            
                        
                     
                    
//                         {selectedDateTime && <p>Selected Date: {(selectedDateTime)}</p>}      
                       
//                         </div>
//                       </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//         </div>
//       </div>
//       <div class="modal-footer text-start">
//       {loaderpickup==true? <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />:  <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={()=>pickup_request()}>Schedule Pickup</button>}
//         <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal" onClick={()=>setModalpickupState({show:false})}>I'll Do this later</button>
//       </div>
    
  
//                 </Modal.Body>
               
//             </Modal>

//         <section class="mb-3 pt-4">
//           <div class="container">
//             <div class="row mb-3">
//               <div class="col-12 col-md-3">
//                 <div class="d-flex">
//                     <div class="">
//                         <h3>Shipments</h3>
//                     </div>
//                     <div class="ps-2 ">
//                         <select class="form-select form-select-sm">
//                           <option>All</option>
//                           <option>Domestic</option>
//                           <option>International</option>
//                         </select>
//                     </div>
//                 </div>
//               </div>
//               <div class="col-12 col-md-6">
//                 <div class="input-group input-group-sm mb-3">
//                     <span class="input-group-text"><i class="material-icons-outlined">search</i></span>
//                     <input type="text" class="form-control" name="search_shipment" placeholder="Enter Tracking Number" onChange={(e)=>handlesearchShipment(e)}/>
//                     <button class="btn btn-outline-primary" onClick={(e)=>shipment_list(null,0,true)}>Search</button>
//                   </div>
//               </div>
//               <div class="col-12 col-md-3 text-end">
//               <a class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#schedule_pikup" onClick={()=>openPickupModal()}><i class="fa fa-truck" aria-hidden="true"></i> Schedule Pickup</a>
//               </div>
              
            
//             </div> 
//             {selectCount.select_count==0?
//             <div>
//             <ul class="nav nav-pills">
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == null) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(null)}>All</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 1) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(1)}>Pickup Awaited</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 9) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(9)}>Pickup Scheduled</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 2) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(2)}>Picked Up</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 3) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(3)}>In Transit</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 4) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(4)}>Out For Delivery</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 5) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(5)}>Delivered</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 6) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer",}} onClick={() => shipmentFilter(6)}>Cancelled</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 7) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer",}} onClick={() => shipmentFilter(7)}>Failed</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 8) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer",}} onClick={() => shipmentFilter(8)}>NDR</a>
//                         </li>
//                         <li class="nav-item">
//                           <a className={(shipmentstate.shipment_status == 10) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer",}} onClick={() => shipmentFilter(10)}>Not Picked</a>
//                         </li>
//                     </ul>
//             </div>
//             :
//             <div>
//                 <a href="#" class="btn btn-outline-secondary btn-sm"><strong>{selectCount.select_count}</strong> Selected</a>
//                 <a href={`/print_label/${selectedShipments.join(',')}`} target="_blank" class="btn btn-outline-secondary btn-sm"><strong><i class="fa fa-file-text" aria-hidden="true"></i></strong> Print Label</a>
//                 <a onClick={()=>label_shipments()} class="btn btn-outline-secondary btn-sm"><strong><i class="fa fa-file-text" aria-hidden="true"></i></strong> Download Label</a>
              
//             </div>   
// }  
//           </div>
//         </section>


//         <section class="mb-3">
//           <div class="container">
//           {isLoading? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"60px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
//       <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
//     </div>:
//             <table class="table table_box">
//                 <thead  class="">
//                     <tr class="brd">
//                     <th style={{width: "5%"}}>
//                         <div class="form-check form-check-inline pt-2">
//                         <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onChange={handleSelectAll}/>
//                         </div>
//                     </th>
//                     <th>Shipment Details</th>
//                     <th>Customer details</th>
//                     <th>Package Details</th>
//                     <th>Shipping Fee</th>
//                     {/* <th>Pickup Address</th> */}
//                     <th>Shipping Carrier</th>
//                     <th style={{width:" 4%"}}>Status</th>
//                     <th style={{width: "4%"}}>Action</th>
//                   </tr>
//                 </thead>
               
//                 <tbody>
                  
//                 {state.shipment_list.map((sub)=>(
//                     <tr class="brd">
//                         <td>
//                             <div class="form-check form-check-inline">
//                                 <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"   onChange={(event) => handleSelectIndividual(event, sub.shipment_id)}
//               checked={selectedShipments.includes(sub.shipment_id)} />
//                             </div>
//                         </td>
//                         <td>
//                             <h2>
//                               {sub.carrier_tracking_number!=undefined && sub.carrier_tracking_number!=""?sub.carrier_tracking_number:""}
//                             </h2>
//                             <small>
//                             <a href={`/customer/shipment_detail/${sub.shipment_id}`} style={{textDecoration:"none"}} >{sub.order_number}</a><br/>
//                             </small>
                          
//                             <small><Moment format="MMM DD YYYY">
//                         {new Date(sub.created*1000)}
//             </Moment> - <Moment format="hh:mm:ss a">
//                         {new Date(sub.created*1000)}
//             </Moment></small>
//                             <h6>
                               
//                             </h6>
//                         </td>
//                         {sub.receiver.map((item)=>(
//                         <td>
//                             <h4>{item.name}</h4>
//                             <small>{item.email}</small>
//                             <h6>{item.mobile}</h6>
//                         </td>
//                         ))}
//                         <td>
//                             <h4>Dead wt. : {sub.total_weight} kg</h4>
//                             <small>{sub.height} x {sub.width} x {sub.length} (cm)</small>
//                             <h6>Volumetric wt.:  {sub.volumetric_weight} Kg</h6>
//                         </td>
//                         <td>
//                             <h4>₹ {sub.total_amount}</h4>
//                             <small>{sub.payment_mode=="cod" || sub.payment_mode=="cash"?<span class="badge rounded-pill bg-info fw-400">cod</span>:<span class="badge rounded-pill bg-warning fw-400">{sub.payment_mode}</span>}</small>
                          
//                         </td>
                   
//                         <td>
//                             <h6 class="text-bold"><strong>{sub.carrier_name}</strong></h6>
                          
//                             <small>{sub.product_type_name}</small>
//                         </td>
//                         <td>
                          
//                             <h2>{sub.status==0?<span class="badge rounded-pill bg-warning fw-400">Pending</span>:sub.status==10?<span class="badge rounded-pill bg-danger fw-400">RTO</span>:sub.status==1?<span class="badge rounded-pill bg-success fw-400">Pick Up Awaited</span>:sub.status==2?<span class="badge rounded-pill bg-warning fw-400">Picked Up</span>:sub.status==3?<span class="badge rounded-pill bg-danger fw-400">In Transit</span>:sub.status==4?<span class="badge rounded-pill bg-primary fw-400">Out For Delivery</span>:sub.status==5?<span class="badge rounded-pill bg-success fw-400">Delivered</span>:sub.status==6?<span class="badge rounded-pill bg-dark fw-400">Cancelled</span>:sub.status==7?<span class="badge rounded-pill bg-secondary fw-400">Failed</span>:sub.status==8?<span class="badge rounded-pill bg-warning fw-400">NDR</span>:sub.status==9?<><span class="badge rounded-pill bg-danger fw-400">Pickup Scheduled</span><br/><br/>
//                             {sub.pickup_date!=undefined && sub.pickup_date!=""?  <small>on {sub.pickup_date!=undefined?sub.pickup_date:""} at {sub.pickup_time!=undefined?sub.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}</h2>
                          
//                         </td>
//                         <td>
//                             <div class="d-flex ">
//                                 <div class="">
                                    
//                                 </div>
//                                 <div class="ps-2 ">
                             
//                                     <div class="dropdown dd_cust">
//                                         <span type=""  data-bs-toggle="dropdown">
//                                         <i class="material-icons-outlined">more_vert</i>
//                                         </span>
//                                         <ul class="dropdown-menu fs-12">
                                      
//                                         <hr/>
//                                         <li><a class="dropdown-item" href={"/tracking/"+ sub.shipment_id}>Search Shipment</a></li>
//                                         <li></li>
                                        
//                                         <li>{sub.label_status==0?
//                        <a class="dropdown-item" href={"/print_label/"+sub.shipment_id}  target="_blank">Print Label</a>:sub.status==7?"":sub.shipping_label!=undefined && sub.shipping_label!=""? <a href={sub.shipping_label} target="_blank" ></a>:<a onClick={()=>{shipping_label(sub)}}  ></a>}</li>
                                       
//                                         {(sub.status==0 || sub.status==1 || sub.status==9)? <li><a class="dropdown-item text-danger" onClick={()=>cancelModal(sub)}>Cancel Shipment</a></li>
//                                        :""}
                                      

//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </td>
//                     </tr>
//                     ))}
                  
//                 </tbody>
//             </table>    
// } 
//           </div>
//         </section>
//         <TablePagination
//                 component="div"
//                 rowsPerPageOptions={[20,25,30,35,40,45,50]}
//                 count={otherStates.total_count}
//                 page={otherStates.page}
//                 onPageChange={handlePageChange}
//                 rowsPerPage={otherStates.rowsPerPage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
               
//                         />
        
//     </section>
   

   
//     </div>
<div>
<Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section" >
         
          <Header/>
             <Modal show={modalState.show} onHide={() => setModalState({ show: false })}>
 <Modal.Header closeButton>
                     <Modal.Title>Do you really want to Cancel ?</Modal.Title>
                 </Modal.Header>
                 <Modal.Body >
                 <div className="row">
                       <div className="col-2 mb-3 mt-5" style={{marginLeft:"25px"}}>
                       <i class="fa fa-envelope " style={{fontSize:"20px",marginTop:"10px"}} aria-hidden="true"></i>
                       </div>
                         <div className="col-md-10 mb-3 mt-5 " style={{marginLeft:"-50px"}}>
                               <input className="form-control" id="first_name" type="text" placeholder="Enter Reason" name="cancel_reason" onChange={(e) => inputHandleChange(e)} required   style={{ background: "transparent", border: "none", borderBottom: "1px solid #000000",  outline:"none", boxShadow:"none"}}/>
                          </div>
                         
                        
                        <div>
                       
                        {cancelshipment==false?
                     <button className="btn mt-3" style={{color: "#fff", backgroundColor: "#D65142", display:"inline-block",textAlign:"center",verticalAlign:"middle",position:"relative",boxSizing:"border-box",marginLeft:"190px",padding: "0.84rem 2.14rem",
     fontSize: "18px",fontWeight:"bold"}} onClick={() =>
       cancelShipment()
                         }>Yes</button>
                         :""}
                     </div>
                     </div>
                 </Modal.Body>
                 <Modal.Footer>
               
                   <Button variant="secondary" onClick={() => setModalState({ show: false })}>
                         Close
                     </Button>
                                               </Modal.Footer>
            </Modal>

                         <Modal show={modalpickupState.show} onHide={() => setModalpickupState({ show: false })} size='lg'>
 <Modal.Header closeButton>
                     <Modal.Title> <h4 class="modal-title">Schedule Your Pickup</h4></Modal.Title>
                 </Modal.Header>
                 <Modal.Body >
              
       <div class="modal-body">
         <div class="row booking_info_bx">
          
             <div class="col-12 col-md-12 mb-3">
               <div class="card bg-light border-0 shadow-sm">
                 <div class="card-body">
                   <div class="d-flex">
                       <div class="p-2 text-primary">
                         <i class="material-icons-outlined">local_shipping</i>
                       </div>
                       <div class="ps-4 ">
                         <h6>Pickup Address</h6>
                         <select class="form-select" name="warehouse_name" onChange={(e)=>inputHandleChangemodal(e)}>
                           <option>Select</option>
                           {addressState.map((sub)=>{
                             return(
                               <option value={sub.address_id}>{sub.warehouse_id}</option>
                            )
                        
                             })}
                        
                         </select>
                       </div>
                   </div>
                 </div>
               </div>
             </div>
             <div class="col-12 col-md-12 mb-3">
               <div class="card bg-light border-0 shadow-sm">
                 <div class="card-body">
                   <div class="d-flex">
                       <div class="p-2 text-primary">
                         <i class="material-icons-outlined">today</i>
                       </div>
                       <div class="ps-4 shcelu_pil">
                         <h6>Schedule Your Pickup</h6>
                         <small>Please select a suitable date and time for the pickup.</small>
                         <div class="mt-3">
                         {nextDates.map((date, index) => (
                           <>
                            <a   class="me-2 btn-time mb-2 text-decoration-none" key={index} onClick={() => handleDateSelect(date)}>{(date)}
                            <select name="time" onChange={(e)=>selectTime(e)}>
                            <option value="9:00">9:00</option>
                            <option value="10:00">10:00</option>
                             <option value="11:00">11:00</option>
                             <option value="12:00">12:00</option>
                             <option value="13:00">13:00</option>
                             <option value="14:00">14:00</option>
                             <option value="15:00">15:00</option>
                             <option value="16:00">16:00</option>
                             <option value="17:00">17:00</option>
                             <option value="18:00">18:00</option>
                           </select>
                           </a>
                         
                         
             </>
           ))}
            
                        
                     
                    
                         {selectedDateTime && <p>Selected Date: {(selectedDateTime)}</p>}      
                       
                         </div>
                       </div>
                   </div>
                 </div>
               </div>
             </div>
         </div>
       </div>
       <div class="modal-footer text-start">
       {loaderpickup==true? <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />:  <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={()=>pickup_request()}>Schedule Pickup</button>}
         <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal" onClick={()=>setModalpickupState({show:false})}>I'll Do this later</button>
       </div>
    
  
                 </Modal.Body>
               
             </Modal>
            <div class="row mb-2">
                <div class="col-12 col-md">
                    <div class="d-flex">
                        <div class="back_btn_wrap">
                            <a href="/customer/dashboard" class="back_btn">
                           <CornerUpLeft/>
                            </a>
                        </div>
                        <div class="">
                            <h2>Shipments</h2>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5 text-end btns">
                    <a class="btn btn-secondary-o btn-sm" onClick={()=>openPickupModal()}><Truck/> Schedule Pickup</a>
                </div>
            </div>
            
            
            <div class="row">
                <div class="col-12 col-md">
                    <div class="row g-1">
                        <div class="col-12 col-md-4">
                            <div class="input-group input-group-sm mb-3">
                                {/* <select class="form-select">
                                    <option>AWB</option>
                                    <option>Order ID</option>
                                    <option>Phone Number</option>
                                </select> */}
                               
                                <input type="text" class="form-control w-50" name="search_shipment" placeholder="Enter Tracking Number" onChange={(e)=>handlesearchShipment(e)} value={searchstate.search_shipment} />
                                <button class="btn btn-outline-primary" onClick={(e)=>shipment_list(null,0,true)}>Search</button>
                            </div>
                        </div>
                        <div class="col-12 col-md-2">
                        <input type="date" class="form-control form-control-sm" id="text" placeholder="Enter details name=" name="date" onChange={(e)=>dateselect(e)}/>
                        </div>
                        <div class="col-12 col-md-2 filter_order">
                            <div class="dropdown">
                            <button type="button" class="btn btn-theme btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                Pickup Location
                            </button>
                            <ul class="dropdown-menu">
    {addressState.map((sub) => (
        <li key={sub.address_id}>
            <div class="form-check form-check-inline">
                <input 
                    class="form-check-input" 
                    type="checkbox" 
                    id={`warehouseCheckbox${sub.address_id}`} 
                    value={sub.address_id} 
                    onChange={(e) => handleWarehouseChange(e, sub.address_id)} 
                    checked={selectedWarehouses.includes(sub.address_id)}
                />
                <label class="form-check-label" for={`warehouseCheckbox${sub.warehouse_id}`}>
                    {sub.warehouse_id}
                </label>
            </div>
        </li>
    ))}
</ul>
                            </div>
                        </div>
                        <div class="col-12 col-md-2 filter_order">
                            <div class="dropdown">
                                <button type="button" class="btn btn-theme btn-sm dropdown-toggle" data-bs-toggle="dropdown">
                                    Payment Mode
                                </button>
                                <ul class="dropdown-menu">
                                <li>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" name="cod" onChange={(e)=>modeChange(e)} checked={paymentmode.cod}/>
                                        <label class="form-check-label" for="inlineCheckbox1">Cash On Delivery</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" name="prepaid" onChange={(e)=>modeChange(e)}  checked={paymentmode.prepaid}/>
                                        <label class="form-check-label" for="inlineCheckbox1">Prepaid</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-md-2 filter_order">
                            <div class="dropdown">
                                <button type="button" class="btn btn-theme btn-sm dropdown-toggle" data-bs-toggle="dropdown">
                                    Status
                                </button>
                                <ul class="dropdown-menu">
                                    {/* <li>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                        <label class="form-check-label" for="inlineCheckbox1">Delivered</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                        <label class="form-check-label" for="inlineCheckbox1">Booked</label>
                                        </div>
                                    </li> */}
                                    <li>
                                    <div class="form-check form-check-inline">
                               <input class="form-check-input" type="checkbox" id="check1" name="pickup_awaited" value="something"  onChange={(e)=>filter(e,1)}/>
                               <label class="form-check-label">Pickup Awaited</label>
                           </div>
                                    </li>
                                    <li>
                                    <div class="form-check form-check-inline">
                               <input class="form-check-input" type="checkbox" id="check1" name="pickup_scheduled" value="something" onChange={(e)=>filter(e,9)}/>
                               <label class="form-check-label">Pickup Scheduled</label>
                           </div>
                                    </li>
                                    <li>
                                    <div class="form-check form-check-inline">
                               <input class="form-check-input" type="checkbox" id="check1" name="picked_up" value="something"  onChange={(e)=>filter(e,2)}/>
                               <label class="form-check-label">Picked Up</label>
                           </div>
                                    </li>
                                  
                        <li>
                        <div class="form-check form-check-inline">
                               <input class="form-check-input" type="checkbox" id="check1" name="in_transit" value="something"  onChange={(e)=>filter(e,3)}/>
                               <label class="form-check-label">In Transit</label>
                           </div>
                        </li>
                          <li>
                          <div class="form-check form-check-inline">
                               <input class="form-check-input" type="checkbox" id="check1" name="out_for_delivery" value="something"  onChange={(e)=>filter(e,4)}/>
                               <label class="form-check-label">Out for Delivery</label>
                           </div>
                          </li>
                         <li>
                         <div class="form-check form-check-inline">
                               <input class="form-check-input" type="checkbox" id="check1" name="delivered" value="something"  onChange={(e)=>filter(e,5)}/>
                               <label class="form-check-label">Delivered</label>
                           </div>
                         </li>
                          <li>
                          <div class="form-check form-check-inline">
                               <input class="form-check-input" type="checkbox" id="check1" name="cancelled" value="something"  onChange={(e)=>filter(e,6)}/>
                               <label class="form-check-label">Cancelled</label>
                           </div>
                         
                          </li>
                         <li>
                         <div class="form-check form-check-inline">
                               <input class="form-check-input" type="checkbox" id="check1" name="failed" value="something"  onChange={(e)=>filter(e,7)}/>
                               <label class="form-check-label">Failed</label>
                           </div>
                         </li>
                           
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {selectCount.select_count==0?
           ""
            : <div class="row mb-2" >
            <div class="col-12 col-md btns">
                <a href="#" class="btn btn-text btn-sm fw-300"><strong>{selectCount.select_count} Selected</strong></a>
                <a onClick={()=>label_shipments()}  class="btn btn-secondary-o btn-sm"><FileDown/> Download Label </a>
                <a href={`/print_label/${selectedShipments.join(',')}`} target="_blank" class="btn btn-secondary-o btn-sm"><BarcodeIcon/> Print Label</a>
                {/* <a href="#" class="btn btn-secondary-o btn-sm"><i data-lucide="file"></i> Print Invoice</a> */}
                {/* <a href="#" class="btn btn-secondary-o btn-sm"><i data-lucide="ban"></i> Cancel</a> */}
            </div>
        </div>} 

            <div class="content-wrapper-data">
                <div class="card border-0">
                  <div class="card-body">
                        <div class="table-data">
                        {isLoading? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"60px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
    </div>:
                            <table class="table table-hover ">
                                <thead>
                                <tr class="table-light">
                                    <th>
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onChange={handleSelectAll} />
                                    </th>
                                    <th>Shipment Details</th>
                                    <th>Customer Details</th>
                                    <th>Value</th>
                                   {showComponent==true? <th>Package Details</th>:<th>Reason</th>}
                                    <th>Pickup Address</th>
                                    <th>Shipping Fee</th>
                                    <th>Shipping Carrier</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {state.shipment_list.map((sub)=>(
                                    <tr class="ship-now-container">
                                        <td>
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onChange={(event) => handleSelectIndividual(event, sub.shipment_id)}
               checked={selectedShipments.includes(sub.shipment_id)}  />
                                        </td>
                                        <td>
                                            <h3><a onClick={()=>openOffdetailcanvasHandler(sub)} style={{cursor:"pointer"}}><strong>AWB {sub.carrier_tracking_number?sub.carrier_tracking_number:""}</strong></a></h3>
                                            <h4 > <a onClick={()=>openOffdetailcanvasHandler(sub)}  style={{cursor:"pointer"}}>Order {sub.order_number}</a></h4>
                                            <h6><Moment format="MMM DD YYYY">
                         {new Date(sub.created*1000)}
             </Moment> - <Moment format="hh:mm:ss a">
                         {new Date(sub.created*1000)}
             </Moment></h6>
                                        </td>
                                        {sub.receiver.map((item)=>(
                        <td>
                        <h4>{item.name}</h4>
                        <h6>{item.city} - {item.pincode}</h6>
                        <h6>{item.mobile}</h6>
                    </td>
                        ))}
                                       
                                        <td>
                                        {/* <h4>₹ {sub.total_amount}</h4> */}
                                            <small><span class="badge rounded-pill bg-warning fw-400">{sub.payment_mode}</span></small>
                                        </td>
                                        {showComponent==true?
                                        <td>
                             <h4>Dead wt. : {sub.total_weight} kg</h4>
                             <small>{sub.height} x {sub.width} x {sub.length} (cm)</small>
                             <h6>Volumetric wt.:  {sub.volumetric_weight} Kg</h6>
                         </td>:
                         <td>
                          {sub.ndr_reason.length>0?
                          sub.ndr_reason.map((ndr)=>(
                           <p>{ndr.reason} </p>
                          )):""}</td>}
                                       
                         {sub.sender.map((item)=>(
                        <td>
                        <h4>{item.name}</h4>
                        <h6>{item.city} - {item.pincode}</h6>
                        <h6>{item.mobile}</h6>
                    </td>
                        ))}
                                        <td>
                                        <h4><strong>₹ {sub.total_amount}</strong></h4>
                                        </td>
                                        <td>
                                        <h4><strong>{sub.service_provider?sub.service_provider:sub.carrier_name}</strong></h4>
                                        <small>{sub.product_type_name?sub.product_type_name:""}</small>
                                        </td>
                                        
                                        <td>
                                            {/* <h5><span class="badge rounded-pill bg-success fw-400">Ready to Ship</span></h5>
                                            <h5><span class="badge rounded-pill bg-info fw-400">Pickup Sheduled</span></h5>
                                            <h5><span class="badge rounded-pill bg-warning fw-400">Pickedup</span></h5>
                                            <h5><span class="badge rounded-pill bg-danger fw-400">In Transit</span></h5>
                                            <h5><span class="badge rounded-pill bg-primary fw-400">Out For Delivery</span></h5>
                                            <h5><span class="badge rounded-pill bg-success fw-400">Delivered</span></h5>
                                            <h5><span class="badge rounded-pill bg-dark fw-400">Cancelled</span></h5>
                                            <h5><span class="badge rounded-pill bg-secondary fw-400">Failed</span></h5>
                                            <h5><span class="badge rounded-pill bg-info fw-400">RTO</span></h5> */}
                                            {sub.status==0?<span class="badge rounded-pill bg-warning fw-400">Pending</span>:sub.status==10?<span class="badge rounded-pill bg-danger fw-400">RTO</span>:sub.status==1?<span class="badge rounded-pill bg-success fw-400">Pick Up Awaited</span>:sub.status==2?<span class="badge rounded-pill bg-warning fw-400">Picked Up</span>:sub.status==3?<span class="badge rounded-pill bg-danger fw-400">In Transit</span>:sub.status==4?<span class="badge rounded-pill bg-primary fw-400">Out For Delivery</span>:sub.status==5?<span class="badge rounded-pill bg-success fw-400">Delivered</span>:sub.status==6?<span class="badge rounded-pill bg-dark fw-400">Cancelled</span>:sub.status==7?<span class="badge rounded-pill bg-secondary fw-400">Failed</span>:sub.status==8?<span class="badge rounded-pill bg-warning fw-400">NDR</span>:sub.status==9?<><span class="badge rounded-pill bg-danger fw-400">Pickup Scheduled</span><br/><br/>
                            {sub.pickup_date!=undefined && sub.pickup_date!=""?  <small>on {sub.pickup_date!=undefined?sub.pickup_date:""} at {sub.pickup_time!=undefined?sub.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}

                                            {/* <div class="custom-buttons button-container">
                                                <div class="d-flex justify-content-between">
                                                    <div class="p-2 ship_now_2">
                                                        <div class="mb-1">
                                                        {sub.label_status==0?
                       <a class="btn btn-secondary btn-sm" href={"/print_label/"+sub.shipment_id}  target="_blank">Print Label</a>:sub.status==7?"":sub.shipping_label!=undefined && sub.shipping_label!=""? <a href={sub.shipping_label} target="_blank" ></a>:<a onClick={()=>{shipping_label(sub)}} title="Print Label" ></a>}
                                                            <a href="#" class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Print Label">
                                                                <i class="fa fa-barcode" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                       

{(sub.status==0 || sub.status==1 || sub.status==9)?
                                                        <div class="mb-1">
                                                            <a class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" onClick={()=>cancelModal(sub)}title="Cancel Shipment">
                                                                <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                            </a>
                                                        </div> 
                                                        :""}
                                                        <div>
                                                            <a  href={"/tracking/"+ sub.shipment_id} class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Shipment Tracking">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                             <div class="custom-buttons button-container">
                                                <div class="d-flex justify-content-between">
                                                  {showComponent==true? <div class="p-2 ship_now_2">
                                                        <div class="mb-1">
                                                        {sub.label_status==0?
                       <a href={"/print_label/"+sub.shipment_id}  target="_blank" class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Print Label"> <i class="fa fa-barcode" aria-hidden="true"></i></a>:sub.status==7?"":sub.shipping_label!=undefined && sub.shipping_label!=""? <a href={sub.shipping_label} target="_blank" ></a>:<a onClick={()=>{shipping_label(sub)}} title="Print Label" ></a>}
                                                           
                                                               
                                                           
                                                        </div>
                                                        {/* <div class="mb-1">
                                                            <a href="#" class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Print Invoice">
                                                                <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                            </a>
                                                        </div> */}
                                                        {(sub.status==0 || sub.status==1 || sub.status==9)?
                                                        <div class="mb-1">
                                                            <a class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" onClick={()=>cancelModal(sub)} title="Cancel Shipment">
                                                                <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                            </a>
                                                        </div> 
                                                        :""}
                                                        <div>
                                                        <a  href={"/tracking/"+ sub.shipment_id} class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Shipment Tracking">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </a>
                                                            {/* <a href="#" class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Shipment Tracking">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </a> */}
                                                        </div>
                                                    </div>:
                                                    <div class="p-2 ship_now_2">
                                                      <div class="mb-1">
                                                       
                       <a onClick={(e)=>openModal(e)} class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Take Action"> <i class="fa fa-bars" aria-hidden="true"></i></a>
                                                        </div>


                                                        
                                                        </div>
                                                   
}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
}
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={ndrmodal} onHide={() => setndrmodal(false )} size='lg'>
                  <Modal.Header closeButton>
                                     <Modal.Title> <h4 class="modal-title">NDR Submit Form</h4></Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body >
                               
                        <div class="modal-body">
                          <div class="row booking_info_bx">
                           
                            <div className='row'>
                              <div className='col-12'>
                               <select className='form-select'>
                                <option>Choose Action</option>
                                <option>Re-Attempt</option>
                                <option>RTO</option>
                                <option>Update Address</option>
                                <option>Update Phone</option>


                               </select>
                              </div>
                            </div>
                            
                         </div>
                       </div>
                       <div class="modal-footer text-start">
                         <button type="button" class="btn btn-primary"  >Submit</button>
                         <button type="button" class="btn btn-outline-danger"  onClick={()=>ndrmodal(false)}>I'll Do this later</button>
                       </div>
                     
                   
                                 </Modal.Body>
                                
                             </Modal>

            
            {openOffdetailcanvas && (
                <section class="shipment_info_offcanvas show">
                    
                    <div class="offcanvas offcanvas-end shipment_details show" id="shipment_details">
                        <div class="offcanvas-header">
                            <div class="offcanvas-title">
                                <div class="row mb-2">
                                    <div class="col-12 col-md">
                                     <h2><small>Shipment #</small>{bookorder.shipment_id}</h2>
                                    </div>
                                    <div class="col-12 col-md-2 text-end">
                                        {/* <span class="badge rounded-pill bg-success status_badge"><i data-lucide="package-check"></i> Delivered</span> */}
                                        {bookorder.status==0?<span class="badge rounded-pill bg-warning fw-400"><PackageCheck/>Pending</span>:bookorder.status==10?<span class="badge rounded-pill bg-danger fw-400"><PackageCheck/>RTO</span>:bookorder.status==1?<span class="badge rounded-pill bg-success fw-400"><PackageCheck/>Pick Up Awaited</span>:bookorder.status==2?<span class="badge rounded-pill bg-warning fw-400"><PackageCheck/>Picked Up</span>:bookorder.status==3?<span class="badge rounded-pill bg-danger fw-400"><PackageCheck/>In Transit</span>:bookorder.status==4?<span class="badge rounded-pill bg-primary fw-400"><PackageCheck/>Out For Delivery</span>:bookorder.status==5?<span class="badge rounded-pill bg-success fw-400"><PackageCheck/>Delivered</span>:bookorder.status==6?<span class="badge rounded-pill bg-dark fw-400"><PackageCheck/>Cancelled</span>:bookorder.status==7?<span class="badge rounded-pill bg-secondary fw-400"><PackageCheck/>Failed</span>:bookorder.status==8?<span class="badge rounded-pill bg-warning fw-400"><PackageCheck/>NDR</span>:bookorder.status==9?<><span class="badge rounded-pill bg-danger fw-400"><PackageCheck/>Pickup Scheduled</span><br/><br/>
                            {bookorder.pickup_date!=undefined && bookorder.pickup_date!=""?  <small>on {bookorder.pickup_date!=undefined?bookorder.pickup_date:""} at {bookorder.pickup_time!=undefined?bookorder.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400"><PackageCheck/>Not Available</span>}
                                    </div>
                                    <div class="col-12 col-md-4 text-end btns">
                                      {bookorder.carrier_tracking_number?
                                        <a href={`/customer/create_ticket?order_number=${bookorder.carrier_tracking_number}`} class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Raise Ticket"> Raise Ticket</a>:""}
                                        {/* <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Print Label"><i data-lucide="barcode"></i></a>
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Print Invoice"><i data-lucide="file"></i></a>
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Shipment Tracking"><i data-lucide="package-search"></i></a>
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Cancel"><i data-lucide="ban"></i></a> */}
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn-close text-reset" onClick={()=>closeOffdetailcanvasHandler()}></button>
                        </div>
                        {failedstate.failed!=""?   
            <div class="alert alert-danger text-center" role="alert">
              Shipment Failed Due to "{failedstate.failed}"
              </div>:""}
                        <div class="offcanvas-body">
                            <div class="">
                                <div class="row mt-3 order_box_cont">
                                    <div class="col-12 col-md-8 ">
                                        <div class="card border-0 mb-3">
                                            <div class="card-body">
                                                <div class="d-flex ">
                                                    <div class="border-end pe-3">
                                                   <ShoppingBag/>
                                                    </div>
                                                    <div class="ps-3 ">
                                                        <h6>Order Details</h6>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Shipment created on channel</small>
                                                        <p>{bookorder.shipment_created_date}</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Shipment created on system</small>
                                                        <p>{bookorder.shipment_created_date}</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Channel</small>
                                                        <p>{bookorder.channel_name?bookorder.channel_name:"Custom-single"}</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                    <small class="text-muted">Shipment value</small>
                                                        <p>₹ {bookorder.items.reduce((total, sub) => total + parseFloat(sub.item_value), 0).toFixed(2)} <span class="badge rounded-pill bg-warning">{bookorder.payment_mode}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card border-0 mb-3">
                                        <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                               <Package/>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Shipment Details</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                            <div class="col-12 col-md">
                                                    <h6>Ship From:</h6>
                                                        <small class="text-muted">Sender Name:</small>
                                                        <p>{bookorder.sender[0].name}</p>
                                                        <small class="text-muted">Sender Address:</small>
                                                        <p>{bookorder.sender[0].address},{bookorder.sender[0].city}, {bookorder.sender[0].state} {bookorder.sender[0].pincode}</p>
                                                        <small class="text-muted">Sender Email:</small>
                                                        <p>{bookorder.sender[0].email}</p>
                                                        <small class="text-muted">Sender Mobile:</small>
                                                        <p>{bookorder.sender[0].mobile}</p>
                                                </div>
                                                <div class="col-12 col-md">
                                                    <h6>Ship To:</h6>
                                                        <small class="text-muted">Receiver Name:</small>
                                                        <p>{bookorder.receiver[0].name}</p>
                                                        <small class="text-muted">Receiver Address:</small>
                                                        <p>{bookorder.receiver[0].address},{bookorder.receiver[0].city}, {bookorder.receiver[0].state} {bookorder.receiver[0].pincode}</p>
                                                        <small class="text-muted">Receiver Email:</small>
                                                        <p>{bookorder.receiver[0].email}</p>
                                                        <small class="text-muted">Receiver Mobile:</small>
                                                        <p>{bookorder.receiver[0].mobile}</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div class="card border-0 mb-3">
                                            <div class="card-body">
                                                <div class="d-flex ">
                                                    <div class="border-end pe-3">
                                                   <TruckIcon/>
                                                    </div>
                                                    <div class="ps-3 ">
                                                        <h6>Shipping Details</h6>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row">
                                                    <div class="col-12 col-md-3 mb-2">
                                                        <small class="text-muted">Shipping Courier</small>
                                                        <p>{bookorder.service_provider?bookorder.service_provider:bookorder.carrier_name}</p>
                                                    </div>
                                                    <div class="col-12 col-md-3 mb-2">
                                                        <small class="text-muted">AWB No.</small>
                                                        <p><a href="">{bookorder.carrier_tracking_number?bookorder.carrier_tracking_number:""}</a></p>
                                                    </div>
                                                    <div class="col-12 col-md-3 mb-2">
                                                        <small class="text-muted">Mode</small>
                                                        <p>{bookorder.product_type_name?bookorder.product_type_name:"----"}</p>
                                                    </div>
                                                    <div class="col-12 col-md-3 mb-2">
                                                        <small class="text-muted">Shipping Courier Service</small>
                                                        <p>{bookorder.service_provider}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card border-0  mb-3">
                                            <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                {/* <i data-lucide="dollar-sign"></i> */}
                                                <DollarSign/>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Payment Details</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Total Paid Amount</small>
                                                    <p>₹ {bookorder.total_amount}</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Payment Mode</small>
                                                    <p>Wallet</p>
                                                </div>
                                                {/* <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Transaction ID</small>
                                                    <p><a href="">3456789876</a></p>
                                                </div> */}
                                            </div>
                                            </div>
                                        </div>

                                        <div class="card border-0  mb-3">
                                            <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                {/* <i data-lucide="package-open"></i> */}
                                                <PackageIcon/>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Package Details</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Applicable Weight (in Kg)</small>
                                                    <p>{bookorder.total_weight}KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Dead Weight (in Kg)</small>
                                                    <p>{bookorder.dead_weight}KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Volumetric Weight (in kg)</small>
                                                    <p>{bookorder.volumetric_weight}KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Dimensions (in cm)</small>
                                                    <p>{bookorder.height} x {bookorder.width} x {bookorder.length}</p>
                                                </div>
                                                <div class="col-12 col-md-6 mb-2">
                                                    <small class="text-muted">Number of Boxes</small>
                                                    <p>1</p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div class="card border-0 mb-3">
                                            <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                {/* <i data-lucide="shopping-basket"></i> */}
                                                <ShoppingBasket/>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Product Details</h6>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="table-responsive ">
                                                <table class="table table-light">
                                                    <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        {/* <th>Category</th> */}
                                                        {/* <th>HSN</th> */}
                                                        {/* <th>SKU</th> */}
                                                        <th>Qty</th>
                                                        <th>Unit price</th>
                                                        {/* <th>Discount</th> */}
                                                        {/* <th>Tax</th> */}
                                                        <th>Total</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                      {bookorder.items.map((sub)=>(
                                                    <tr>
                                                        <td>{sub.item_name}</td>
                                                        {/* <td>1689070274414</td> */}
                                                        {/* <td>samsung-A50</td> */}
                                                        {/* <td>1</td> */}
                                                        <td>{sub.item_quantity}</td>
                                                        <td>{sub.item_value}</td>
                                                        {/* <td>100.00</td> */}
                                                        {/* <td>10.00</td> */}
                                                        <td> {bookorder.items.reduce((total, sub) => total + parseFloat(sub.item_value), 0).toFixed(2)}</td>
                                                    </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        </div>

                                        
                                        
                                        
                                    </div>
                                    <div class="col-12 col-md-4">
                                    <div class="card border-0 shadow-sm tracking_xb">
                                        <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                              <PackageSearch/>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Tracking Info</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            {trackingstate.track!='' && stateship.shipmentDetail!='' ?
                                            <div class="progress-container  position-relative">
                                              {trackingstate.isLoading==false?
                                               trackingstate.track.history.length>0?
                                               trackingstate.track.history.map((sub)=>(
                                                <div class="step-container">
                                                <div class="step active">
                                                    <i class="material-icons-outlined">radio_button_checked</i>
                                                </div>
                                                <div class="progress"></div>
                                                <div class="step-info">
                <h4>{sub.message} </h4>
                <p>{sub.location}</p>
                <p><Moment format="MMM DD YYYY hh:mm:ss a">
                 {(sub.time)}
     </Moment></p>
            </div>
                                                </div>
                                             )) :"No Data Available"  :<ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} />}
                                              
                                            </div>:"No Data Available"}
                                          
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            )}
                <TablePagination
                component="div"
                 rowsPerPageOptions={[500,800,1000]}
                 count={otherStates.total_count}
                 page={otherStates.page}
                 onPageChange={handlePageChange}
                 rowsPerPage={otherStates.rowsPerPage}
                 onRowsPerPageChange={handleChangeRowsPerPage}
               
                         />
       
        {/* <!-- Main content area END --> */}
  
      
  </div>
</div>
  )
}

export default Customer_shipments
