import React from 'react'

const About = () => {
  return (
    <div>
      <iframe src="https://www.shipdartexpress.com/about" title="About Us" style={{width: "100%",
            height: "100vh",
            border: "none"}}></iframe>    
    </div>
  )
}

export default About
