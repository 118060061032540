import React from 'react'

export const Privacy = () => {
  return (
    <div>
      <iframe src="https://www.shipdartexpress.com/policy" title="Privacy Policy" style={{width: "100%",
            height: "100vh",
            border: "none"}}></iframe>    
    </div>
  )
}
