import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"

import { useLocation } from 'react-router-dom';



const Forgot_password = () => {
    const location = useLocation();
    let navigate =useNavigate();
    // console.log("location",location);
    //location.state.email
    const [userState, setUserState] = useState({ full_name: "" });
    const [state, setState] = React.useState({ email: location.state.email,otp:""  })
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    });
    const axios_registration_api = (event) => {
        event.preventDefault();
        if (state.email != '' && state.otp!="") {
          let full_api = config.apiUrl + `/user/forgot_otp_verification`;
         // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
          let sendData = { email: state.email, otp: state.otp  };
        
          // console.log(sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
            // console.log("response11",res);
        //    if(res.data.status==true){
        //     Toast.fire({
        //         background:"#206bc4",
        //         type: 'success',
        //         title: res.data.message ,
        //         color:"white"
        //       });
        //       let sendState = { mobile_number:sendData.mobile_number};
        //       // console.log("sendState === " , sendState)
            
        //        navigate('/customer/verify_sms',{state:sendState})
        //    }
        //    else{
        //     Toast.fire({
        //         background:"#206bc4",
        //         type: 'success',
        //         title: res.data.message ,
        //         color:"white"
        //       });
        //    }
        if(res.data.status==true){
            // localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            // setUserState({full_name:res.data.output.full_name})
            // config.UserId = res.data.output.customer_id
            let sendState = { email:state.email};
           Toast.fire({
               background:"#206bc4",
               type: 'success',
               title: res.data.message ,
               color:"white"
             });
             setTimeout(()=>{
               navigate('/customer/create_password' , {state:sendState})
             },2000)
           }
           else{
            Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
           }
            
          }).catch((e) => {
           
          });
        }
      };
      const handleChangeBroker = (event) => {
        setState({
                 ...state,
                 [event.target.name]: event.target.value,
               });
  
      }
  return (
    // <div>
     
    
    // <section class=" register_box_00">
    //     <nav class="navbar navbar-expand-sm pt-4">   
    //         <div class="container">
    //             <a class="navbar-brand" href="javascript:void(0)">
    //                 <img src="/img/logo_5.png" class="img-fluid" />
    //             </a>
    //             <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
    //             <span class="navbar-toggler-icon"></span>
    //             </button>
    //             <div class="collapse navbar-collapse" id="mynavbar">
    //             <ul class="navbar-nav me-auto">
                    
    //             </ul>
    //             <div class="d-flex">
                    
    //             </div>
    //             </div>
    //         </div>
    //     </nav>
    
    
    
    //   <div class="container">
    //     <div class="row justify-content-center">
    //       <div class="col-12 col-md-5">
    //         <div class="card">
    //           <div class="card-body">
    //             <h4>Forgot Password</h4>
    //             <small>Hey, We have sent you a six-digit OTP on your email <strong>{state.email}</strong>. Please check your inbox or spam folder and enter it below.</small>
    //             <div class="row mt-4">
    //                 <div class="col-12 mb-3">
    //                     <div class="input-group">
    //                         <span class="input-group-text"><i class="material-icons-outlined">sms</i></span>
    //                         <input type="number" class="form-control form-control-lg" placeholder="Enter OTP" required name="otp" onChange={(e)=>handleChangeBroker(e)} />
    //                     </div>
    //                 </div>
    //             </div>
                
    
    //             <div class="d-grid py-4">
    //               <a onClick={(e)=>axios_registration_api(e)} class="btn btn-primary btn-block">Confirm OTP</a>
    //             </div>
    
    //             <small>New User? <strong><a href="/customer/register" class="register_box_00_a text-decoration-none">Sign Up</a></strong></small>
    //           </div>
    //         </div>
    
          
    //       </div>
    //     </div>      
    //   </div>
    // </section>
   
    // </div>
    <div>
        <section class="mb-3 ">
      <div class="login_box_v2">
        <div class="row g-0">
          <div class="col-12 col-md-6 left">
            <img src="../img/logo_sky.png" class="img-fluid" />
            <h4>Forgot Password</h4>
                 <small>Hey, We have sent you a six-digit OTP on your email <strong>{state.email}</strong>. Please check your inbox or spam folder and enter it below.</small>
            <div class="row py-3">
               
                <div class="col-12 mb-3">
                {/* <span class="input-group-text"><i class="material-icons-outlined">sms</i></span> */}
                             <input type="number" class="form-control form-control-lg" placeholder="Enter OTP" required name="otp" onChange={(e)=>handleChangeBroker(e)} />
                </div>
              
                <div class="col-12 mb-3">
                    <div class="d-grid">
                      <a onClick={(e)=>axios_registration_api(e)} class="btn btn-primary btn-block">Confirm OTP</a>
                    </div>
                </div>
            </div>

            <hr/>
            <p><a href="/customer/login">Return to login</a> </p>
            <div class="">
            <ul class="nav">
            <li class="nav-item">
        <a class="nav-link" href="/customer/terms" >Terms & Condition</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" href="/customer/policy" >Privacy Policy</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" href="/customer/about" >Help</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" href="/customer/contact"  >Contact</a>
    </li>
</ul>
            </div>
            <div class="mt-3">
                <small>© 2023. All Rights Reserved.</small>
            </div>
          </div>
          <div class="col-12 col-md-6 right">
          <div id="login_panel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="0" class="active"></button>
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="1"></button>
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="2"></button>
                </div>
                <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="carousel-caption">
                        <h3>Effortless Deliveries, Every Time.</h3>
                        <p>Our delivery software ensures seamless operations, from order to doorstep. With real-time tracking and smart route optimization, your deliveries are always on time, hassle-free.</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-caption">
                    <h3>Deliver Beyond Boundaries.</h3>
                    <p>Break the barriers of distance with our advanced delivery software. Reach customers far and wide, with precision and speed. Experience the ease of global deliveries with just a click.</p>
                </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-caption">
                    <h3>Speed, Accuracy, Reliability - Delivered!</h3>
                    <p>Transform your delivery experience with our software engineered for precision. Say goodbye to errors and delays. Every package reaches its destination swiftly, accurately, and reliably, enhancing customer satisfaction.</p>
                </div>
                </div>
                </div>

                <button class="carousel-control-prev" type="button" data-bs-target="#login_panel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon"></span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#login_panel" data-bs-slide="next">
                <span class="carousel-control-next-icon"></span>
                </button>
                </div>
          </div>
        </div>      
      </div>
    </section>
    </div>
  )
}

export default Forgot_password
